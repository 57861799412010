import { IEntity, IEntityData } from "@core/Models/i-entity";
import { IEventSourcingStoreReactClient } from "./EventSourcingClientBase";
import { QueryVars } from "@core/Utils/MongoQueryParser";
import { IQuerySpecification } from "./Implementation/LiveQuery";

export enum BulkUpdateStatus {
    Done = "Done",
    Error = "Error",
}

export interface IBulkUpdateResult {
    status: BulkUpdateStatus;
    errorMessage?: string;
    numberOfCompleted: number;
}

export class BulkUpdateApi {
    static readonly rowLimit = 10000;

    private store: IEventSourcingStoreReactClient;
    private filterQuery: IQuerySpecification;
    private queryVars: QueryVars;
    private selectedEntityIds: string[] | "all";
    private selectedCount: number;

    private onStartUpdateHandlers: (() => void)[] = [];
    private onAfterUpdateHandlers: ((result: IBulkUpdateResult) => void)[] = [];

    constructor(store: IEventSourcingStoreReactClient, filterQuery: IQuerySpecification | null, queryVars: QueryVars, selectedEntityIds: string[] | "all", allCount: number) {
        this.store = store;
        this.queryVars = queryVars;
        this.selectedEntityIds = selectedEntityIds;

        if (filterQuery == null) {
            this.filterQuery = {
                where: "getAll",
                orderBy: null,
            }
        }
        else {
            this.filterQuery = filterQuery;
        }

        if (selectedEntityIds == "all") {
            this.selectedCount = allCount;
        }
        else {
            this.selectedCount = selectedEntityIds.length;
        }
    }

    public async getSelectedEntityIds(): Promise<string[]> {
        if (this.selectedEntityIds == "all") {
            return await this.store.queryIds(this.filterQuery, this.queryVars);
        }
        else {
            return this.selectedEntityIds;
        }
    }

    public async update(callback: (entity: IEntity) => IEntityData): Promise<IBulkUpdateResult> {
        this.onStartUpdate();

        const selectedEntityIds = await this.getSelectedEntityIds();
        const result = await this.store.multiUpdate(selectedEntityIds, callback);

        this.onAfterUpdate(result);

        return result;
    }

    public getCount(): number {
        return this.selectedCount;
    }

    public get length(): number {
        return this.selectedCount;
    }

    public handleOnStartUpdate(handler: () => void) {
        this.onStartUpdateHandlers.push(handler);
    }

    public unhandleOnStartUpdate(handler: () => void) {
        this.onStartUpdateHandlers = this.onStartUpdateHandlers.filter(x => x != handler);
    }

    private onStartUpdate() {
        this.onStartUpdateHandlers.forEach(handler => {
            handler();
        })
    }

    public handleOnAfterUpdate(handler: (result: IBulkUpdateResult) => void) {
        this.onAfterUpdateHandlers.push(handler);
    }

    public unhandleOnAfterUpdate(handler: (result: IBulkUpdateResult) => void) {
        this.onAfterUpdateHandlers = this.onAfterUpdateHandlers.filter(x => x != handler);
    }

    private onAfterUpdate(result: IBulkUpdateResult) {
        this.onAfterUpdateHandlers.forEach(handler => {
            handler(result);
        })
    }
}