import { ComponentDesc, ComponentImpl, IMultiEditor, IMultiEditorDesc, IPlugin, IPluginDesc, IReactComponentDecorator, IReactComponentDecoratorDesc, IRoute, IRouteInfo as IRouteDesc } from "@pluginShared/i-plugin-contract";
import React, { memo } from "react";
import * as Babel from '@babel/standalone';
import { ICrmApi } from "@pluginShared/i-crm-api";
import styles from 'src/App/Pages/PluginPage/PluginPage.module.scss';
import { modulesApiAddGeneratedModule, modulesApiDeleteGeneratedModule } from "@core/Api/modules-api";
import { IInstalledModule } from "@core/Models/installedModule";
import { IGeneratedModuleInfo, IModuleInfo } from "@core/Models/autogenerated/plugins.models";
import Logger from "js-logger";
import { InvalidHint } from "@core/VisualComponents/Inputs/InvalidHint/InvalidHint";
import { v4 as uuidv4 } from 'uuid';

type FunctionalComponent<P> = (props: P) => JSX.Element;

export interface DecorableComponent<P> extends ComponentDesc<P>, FunctionalComponent<P> {};

let initial_components: { [key: string]: DecorableComponent<any> } = {};
let extensions: { [key: string]: { func: <P>(original: FunctionalComponent<P>, props: P) => JSX.Element, order: number, pluginId: string }[]; } = {};
let extensions_version =  0;
let prepared_components: { [key: string]: DecorableComponent<any>; } = {};
export const loadedRoutes: Record<string, IRoute> = {};
export const loadedMultiEditors: Record<string, IMultiEditor> = {};



function addDecorator(decorator: IReactComponentDecorator<any>, pluginId: string) {
    extensions[decorator.component.componentName] = extensions[decorator.component.componentName] ?? [];
    extensions[decorator.component.componentName].push({ func: decorator.func, order: decorator.order, pluginId: pluginId });
    extensions_version++;
    delete prepared_components[decorator.component.componentName];
}

function removeDecorator(decoratorComponentName: string, pluginId: string) {
    if (extensions[decoratorComponentName] != null) {
        extensions[decoratorComponentName] = extensions[decoratorComponentName].filter(x => x.pluginId != pluginId);
        delete prepared_components[decoratorComponentName];
        extensions_version++;
    }
}

export function addComponent(componentImpl: ComponentImpl<any>) {
    initial_components[componentImpl.name] = initDecorableComponent(componentImpl.name, componentImpl.component);
}

export function removeComponent(componentName: string) {
    delete initial_components[componentName];
}

export function registerComponent<P>(desc: ComponentDesc<P>, impl: FunctionalComponent<P>) {
    const MemoImpl = memo(impl) as any;
    impl = (props) => <MemoImpl {...props}/>;

    addComponent({
        name: desc.componentName,
        component: impl,
    });

    return (props: P) => {
        return component({
            componentName: desc.componentName
        } as ComponentDesc<P>)({...props, _pluginVersion: extensions_version});
    };
}

function initDecorableComponent<P>(componentName: string, comp: FunctionalComponent<P>): DecorableComponent<P> {
    let result = comp as DecorableComponent<P>;
    result.componentName = componentName;
    return result;
}

export function component<P>(comp: ComponentDesc<P>): DecorableComponent<P> {
    if (prepared_components[comp.componentName] != null)
        return prepared_components[comp.componentName];

    if (initial_components[comp.componentName] == null)
        return initDecorableComponent(comp.componentName, () => React.createElement(React.Fragment));

    if (extensions[comp.componentName] == null)
        return initial_components[comp.componentName];

    let compExtensions = extensions[comp.componentName].sort((a, b) => a.order - b.order);
    let compInitialComponent = initial_components[comp.componentName];

    let wrapper: FunctionalComponent<P> = compInitialComponent;
    for (let extension of compExtensions) {
        let previousF = wrapper;
        wrapper = function (props: P) {
            try {
                return extension.func(previousF, props);
            }
            catch (e: any) {
                Logger.error(`Runtime error in plugin with id '${extension.pluginId}'`, e);
                return <InvalidHint text={`Plugin error with id '${extension.pluginId}': ${e.message}`} inline/>
            }
        };
    }

    const WrapperMemo = memo(wrapper) as any;
    wrapper = (props) => <WrapperMemo {...props}/>;

    const decorableWrapper = initDecorableComponent(comp.componentName, wrapper)
    prepared_components[comp.componentName] = decorableWrapper;

    return decorableWrapper;
}

export function installPlugin(plugin: IPlugin, info: IModuleInfo): IInstalledModule {
    for (let route of plugin.routes ?? []) {
        route.id = uuidv4(); // TODO: remove
        loadedRoutes[route.id] = route;
    }

    for (let decorator of plugin.decorators ?? []) {
        addDecorator(decorator, info.name);
    }

    for (let component of plugin.components ?? []) {
        addComponent(component);
    }

    for (let multiEditor of plugin.multiEditors ?? []) {
        multiEditor.id = uuidv4();
        loadedMultiEditors[multiEditor.id] = multiEditor;
    }

    const routesDesc = plugin.routes.map(x => ({
        id: x.id,
        path: x.path,
        groupCaption: x.groupCaption,
        menuCaption: x.menuCaption,
        menuIcon: x.menuIcon,
    }) as IRouteDesc);

    const componentDesc = plugin.components.map(x => ({ componentName: x.name }) as ComponentDesc<any>);
    const decoratorDesc = plugin.decorators.map(x => ({ 
        order: x.order, 
        component: { 
            componentName: x.component.componentName 
        }
    }) as IReactComponentDecoratorDesc<any>);

    const multiEditorsDesc = (plugin.multiEditors ?? []).map(x => ({ id: x.id, caption: x.caption }) as IMultiEditorDesc);

    const pluginDesc = {
        routes: routesDesc,
        components: componentDesc,
        decorators: decoratorDesc,
        multiEditors: multiEditorsDesc,
    } as IPluginDesc;

    return {info, installation: pluginDesc};
}

export function uninstallPlugin(module: IInstalledModule) {
    for (let route of module.installation.routes ?? []) {
        delete loadedRoutes[route.id ?? ""];
    }

    for (let decorator of module.installation.decorators ?? []) {
        removeDecorator(decorator.component.componentName, module.info.name);
    }

    for (let component of module.installation.components ?? []) {
        removeComponent(component.componentName);
    }
}

export async function addGeneratedPlugin(info: IGeneratedModuleInfo, crmApi: ICrmApi): Promise<IInstalledModule> {
    const transformResult = Babel.transform(info.code, { presets: ["react"], parserOpts: { allowReturnOutsideFunction: true } });
    var output = transformResult.code;

    if (output == null)
        throw Error("Babel.transform fail to transform code.");

    const func = new Function('React', 'api', output);
    const result: IPlugin = func(React, crmApi);

    result.routes = result.routes.map(route => {
        const addDefaultStyleWrapper = () => <div className={styles.pluginPage}>{route.func()}</div>;
        return {
            ...route,
            func: addDefaultStyleWrapper,
        }
    });

    return installPlugin(result, info);
}

export async function addPluginCode(info: IGeneratedModuleInfo, crmApi: ICrmApi): Promise<IInstalledModule | null> {
    const result = await addGeneratedPlugin(info, crmApi);

    if (result != null) {
        await modulesApiAddGeneratedModule({
            tenant: { useDefaultTenant: true },
            module: info,
        });
    }

    return result;
}

export async function removePlugin(module: IInstalledModule): Promise<void> {
    uninstallPlugin(module);

    await modulesApiDeleteGeneratedModule({
        tenant: { useDefaultTenant: true },
        id: (module.info as IGeneratedModuleInfo).id
    })
}