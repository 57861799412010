import _ from 'lodash';
import { z } from 'zod';

import { BaseTool, CompositeTool, IApiForAIAgent } from "./ai-api";
import { TenantConfigValidator } from '@core/Validators/TenantConfigValidator';
import { PluginManagerTool } from './pluginAiManager';
import { ListUsersTool } from './usersManager';
import { CrmFieldViewType, ICrmField, ITenantConfig, ITableConfig, CrmSortDirection } from '@core/Models/autogenerated/tenantConfig.models';
import { VisibilityOnList } from '@core/Models/tenantConfig.models';

const getFieldManagerPrompt = async (api: IApiForAIAgent) => {
    const fields = await new ListFieldsTool(api).run("");
    const currentTableId = api.getCurrentSelectedTable()?.tableId;

    return `
You are an AI CRM fields (also known as columns) management tool.
You need to call upon various tools to do your job. All inputs must be filled by you using query context and common sense.
Output must describe all successfull operations.
If you don't know which data type to use, then use a string.
Your tools work with fields with following structure:
{
    "id": <mandatory. field identificator written in camel case on english>,
    "caption": <mandatory. how field been displayed to user >,
    "viewType": <mandatory. from list from below>,
    "placeholder": <optional. placeholder for user input>,
    "autocomplete": <optional. is autocomplete based on input history enabled for this field. by default false, true if explicitely asked>,
    "visibilityOnList": <optional. Must be one of '${VisibilityOnList.Title}' or '${VisibilityOnList.Subtitle}'. In list mode view, '${VisibilityOnList.Title}' is used for the most important information, while '${VisibilityOnList.Subtitle}' is used for rest details. If 'viewType' is '${CrmFieldViewType.Comments}' or '${CrmFieldViewType.Array}' then 'visibilityOnList' can't be '${VisibilityOnList.Title}'>,
    "style": <optional. object with CSS styles used for <td> for column data. example: {"maxWidth": "150px","wordWrap": "break-word"}>,
    "options": <optional. array of options for selection for field with predefined values. example:[{"label": "created","value": "Created"},{"label": "payed","value": "Payed"}]. Options for viewType '${CrmFieldViewType.Tags}' also contain color. The color can be specified as a hex string or one of a set of presets: "magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple">,
    "textEllipsis": <optional. display text truncated to this number of characters>,
    "fields": <optional. array of fields for array type. 'viewType' of this fields can't be '${CrmFieldViewType.Comments}' or '${CrmFieldViewType.Array}'. example: [{"id":"date","type":"Date","viewType":"Date","caption":"Date","placeholder":"Date"},{"id":"name","type":"String","viewType":"String","caption":"Name","placeholder": "Name"}]>,
}

list of field viewTypes:
* ${CrmFieldViewType.Comments}
* ${CrmFieldViewType.String}
* ${CrmFieldViewType.Decimal}
* ${CrmFieldViewType.Date}
* ${CrmFieldViewType.Time}
* ${CrmFieldViewType.YesNo}
* ${CrmFieldViewType.Combobox}
* ${CrmFieldViewType.MultiString}
* ${CrmFieldViewType.Url}
* ${CrmFieldViewType.Array}
* ${CrmFieldViewType.Tags}
* ${CrmFieldViewType.Unknown}

IMPORTANT:
* If you are asked to create a column with an unusual or complex data type (e.g., an array of objects, an array of simple values,
or a single object with several fields), do not create multiple columns. Instead, make a single column with
viewType '${CrmFieldViewType.Array}' and then create a decorator plugin for this column. This approach is useful,
for example, when a user needs a single column with multiple subcolumns.
* If you just need to change a column, you don't need to create a new plugin, just change the previous one, specifying the column id.
* Don't forget to pass the column id and fields to the plugin creation function.
* If you created a column with viewType '${CrmFieldViewType.Array}', be sure to create a decorator plugin to display the values of this column, passing the column id and fields values.

Table currently opened by the user is { "tableId": "${currentTableId}"}
IMPORTANT: The list of columns that list_fields returns is actual. Ignore the chat history and your previous actions. When generating a response, always use the current data retrieved from the list_fields function to ensure that the information about the columns in the system is accurate and up-to-date.
The summary information on available tables and fields(in short format) in CRM:
${fields}
`
}

export class FieldsManagerTool extends CompositeTool {
    name: string = "fields_manager";
    description: string = `
This function is useful when a user wants to manage or get information about tables and CRM fields (also known as columns).
You can change column properties, add, update, delete columns. You can add or change tables. This function can not implement conditional behavior instead use plugin functions.
Do not try to use this function if user wants to change cells or rows or use conditional behaviour.
You must use this function for a single elementary modification task.
For complex tasks, you must divide the task into elementary tasks and call this function for each one.
The input is a text with a human-readable description of the field modification task (preserve user language).
`;

    public getPrompt = (): Promise<string> => getFieldManagerPrompt(this.api);

    toolkit: BaseTool[];

    constructor(api: IApiForAIAgent, access_token : string) {
        super(api, access_token, 'gpt-4o');

        this.toolkit = [
            new ListFieldsTool(this.api),
            new AddFieldTool(this.api),
            new DeleteFieldTool(this.api),
            new GetFieldInfoTool(this.api),
            new UpdateFieldTool(this.api),
            new MoveFieldTool(this.api),
            new PluginManagerTool(this.api, access_token),
            new ListUsersTool(this.api),
            new AddTableTool(this.api),
        ];
    }

    async run(query: string) : Promise<string> {
        console.log(`*FieldsManagerTool ${query}`)

        const agent = await this.createAgent();

        const response = await agent.invoke({
            input: query,
        });

        return response.output;
    }
}

export class AddFieldTool extends BaseTool {
    name: string = "add_field";
    description: string =
        "useful for when you need add field(aka column) to CRM. Input is a JSON in format { tableId: <target table id>, field: <field config according to field structure>}";

    async run(query: string): Promise<string> {
        let task : {tableId: string, field: ICrmField};

        try {
            task = JSON.parse(query);
        }
        catch (error: any) {
            return "Exception: " + error.toString() + ". Input is incorrect JSON";
        }

        const validatorError = TenantConfigValidator.processField(task.field);
        if (validatorError) {
            return validatorError;
        }

        let config = this.api.getOrderConfig();
        if (!config)
            return "Error: configuration not loaded";

        const checkResult = this.isAvailableAdd(config, task.tableId, task.field);
        if (checkResult) {
            return checkResult;
        }

        let tables = config.tables.map(tableConfig => {
            if (tableConfig.tableId === task.tableId) {
                let newFields = [...tableConfig.fields, task.field];
                return {
                    ...tableConfig,
                    fields: newFields,
                };
            } else {
                return tableConfig;
            }
        });

        let newConfig: ITenantConfig = {...config, tables: tables}

        await this.api.saveOrderConfig(newConfig);
        return "Field successfully added.";
    }

    isAvailableAdd(config:ITenantConfig, tableId: string, field: ICrmField): string | undefined {
        const tableConfig = config.tables.find(x => x.tableId === tableId);

        if (tableConfig == null)
            return `Table with id '${tableId}' is absent`;
        
        if (tableConfig.fields.find(x => x.id.trim() === field.id)) {
            return `Field with id '${field.id}' is already exist in CRM`;
        }

        if (tableConfig.fields.find(x => x.caption.trim() === field.caption)) {
            return `Field with caption '${field.caption}' is already exist in CRM`;
        }

        return;
    }
}

export class DeleteFieldTool extends BaseTool {
    name: string = "delete_field";
    description: string =
        "useful for when you need to delete column in CRM. Input is a JSON in format { tableId: <target table id>, fieldId: <field id to delete>}";

    async run(query: string): Promise<string> {
        try {
            console.log(`*DeleteFieldTool ${query}`);

            let task : {tableId: string, fieldId: string};
            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". Input is incorrect JSON";
            }

            let config = this.api.getOrderConfig();
            if (!config)
                return "Error: configuration not loaded";
        
            let newFields: any[] = [];
            let fieldFounded = false;
            let tableFounded = false;

            let tables = config.tables.map(tableConfig => {
                if (tableConfig.tableId === task.tableId) {
                    tableFounded = true;
                    
                    for (let f of tableConfig.fields) {
                        if (f.id !== task.fieldId) {
                            newFields.push(f);
                        } else {
                            fieldFounded = true;
                        }
                    }

                    return {
                        ...tableConfig,
                        fields: newFields
                    }
                } else {
                    return tableConfig;
                }
            });

            if (!tableFounded)
                return `Table with id ${task.tableId} is absent. You can use list_fields to look at available fields.`;

            if (!fieldFounded)
                return `Error: field was not deleted because because field with id '${task.fieldId}' not found in table '${task.tableId}'. You can use list_fields to look at available fields.`;
        
            let newconfig = { ...config, tables: tables };
            await this.api.saveOrderConfig(newconfig);
            return "field deleted successfully";
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}

export class GetFieldInfoTool extends BaseTool {
    name: string = "get_field_info";
    description: string =
        "useful for when you need to list all properites of specific field(aka column) in CRM. Input is a JSON in format { tableId: <target table id>, fieldId: <field id>}. Output is an object with JSON schema returned by get_field_schema:";
    async run(query: string) : Promise<string> {
        try {
            console.log(`*GetFieldInfoTool ${query}`);

            let task : {tableId: string, fieldId: string};
            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". Input is incorrect JSON";
            }

            let config = this.api.getOrderConfig();
            if (!config)
                return "Error: configuration not loaded";

            let result = null;

            const tableConfig = config.tables.find(x => x.tableId === task.tableId);
            if (tableConfig == null)
                return `Table with id '${task.tableId}' is absent. You can use list_fields to look at available fields.`;

            result = tableConfig.fields.find(x => x.id === task.fieldId);
            if (!result) {
                return `Error: can not read field properites because field with id '${task.fieldId}' not found in table '${task.tableId}'. You can use list_fields to look at available fields.`; 
            }
            return JSON.stringify(result);
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }
}

export class UpdateFieldTool extends BaseTool {
    name: string = "update_field_tool";
    description: string =
        "useful for when you need to update field(aka column) properites in CRM. Make sure you have full current field configuration. Input is a JSON in format { tableId: <target table id>, field: <field config according to field structure with id of target field>}.";
    async run(query: string) : Promise<string> {
        try {
            console.log(`*UpdateFieldTool ${query}`);

            let task : {tableId: string, field: ICrmField};
            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". Input is incorrect JSON";
            }

            let config = this.api.getOrderConfig();
            if (!config)
                return "Error: configuration not loaded";
        
            let fieldFounded = false;
            let tableFounded = false;

            const tables = config.tables.map(tableConfig => {
                if (tableConfig.tableId === task.tableId) {
                    tableFounded = true;

                    const fields = tableConfig.fields.map(fieldConfig => {
                        if (fieldConfig.id === task.field.id) {
                            fieldFounded = true;
                            return task.field;
                        } else {
                            return fieldConfig;
                        }
                    });

                    return {
                        ...tableConfig,
                        fields: fields
                    }
                } else {
                    return tableConfig;
                }
            });

            if (!tableFounded)
                return `Table with id '${task.tableId}' is absent. You can use list_fields to look at available fields.`;

            if (!fieldFounded)
                return `Error: field was not updated because field with id '${task.field.id}' not found in table '${task.tableId}'. You can use list_fields to look at available fields.`;

            let newconfig = { ...config, tables: tables };

            this.api.saveOrderConfig(newconfig);
            return "field updated successfully";
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }
}

export class MoveFieldTool extends BaseTool {
    name: string = "move_field_tool";
    description: string =
`This tool is useful for when you need to move a field (also known as a column) to a different place in a list. Input should be a JSON object in the following format: 
{ 
  "tableId": <target table ID>, 
  "fieldId": <ID of the field to move>, 
  "after": <ID of the field after which the source field needs to be placed, or null to place it first> 
}.`;

    schema = z.object({
        tableId: z.string(),
        fieldId: z.string(),
        after: z.string().nullable()
    });

    async run(task : {tableId: string, fieldId: string, after: string|null}) : Promise<string> {
        try {
            console.log(`*MoveFieldTool ${JSON.stringify(task)}`);

            if (!task.tableId)
                return "Invalid input: 'tableId' not set"
            if (!task.fieldId)
                return "Invalid input: 'fieldId' not set"
            if (task.after == null)
                return "Invalid input: 'after' not set"

            let config = this.api.getOrderConfig();
            if (!config)
                return "Error: configuration not loaded";
        
            let newFields : any[] = [];
            let fieldFounded = false;
            let fieldAfterFounded = false;
            let tableFounded = false;

            const tables = config.tables.map(tableConfig => {
                if (tableConfig.tableId !== task.tableId)
                    return tableConfig;
                tableFounded = true;

                let source = _.find(tableConfig.fields, (x: any) => x.id === task.fieldId);

                if (!source)
                    return tableConfig;

                fieldFounded = true;

                if (!task.after) {
                    newFields.push(source);
                    fieldAfterFounded = true;
                }

                for(let f of tableConfig.fields) {
                    if (f.id == task.fieldId)
                        continue;

                    if (f.id === task.after) {
                        newFields.push(f);
                        newFields.push(source);
                        fieldAfterFounded = true;
                    } else {
                        newFields.push(f);
                    }
                }

                return {
                    ...tableConfig,
                    fields: newFields
                };
            });

            if (!tableFounded)
                return `Table with id '${task.tableId}' is absent. You can use list_fields to look at available fields.`;

            if (!fieldFounded)
                return `Error: field was not moved because field with id '${task.fieldId}' not found in table '${task.tableId}'. You can use list_fields to look at available fields.`;
        
            if (!fieldAfterFounded)
                return `Error: field was not moved because 'after' field with id '${task.after}' not found in table '${task.tableId}'. You can use list_fields to look at available fields.`;
        
            let newconfig = { ...config, tables: tables };

            this.api.saveOrderConfig(newconfig);
            return "field moved successfully";
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }
}

export class ListFieldsTool extends BaseTool {
    name: string = "list_fields";
    description: string =
        "useful for when you need to list all fields(aka columns) in CRM. Input is empty. Output is actual fields represented as array of objects of field structure.";

    showHiddenFields = false;

    constructor(api: IApiForAIAgent, showHiddenFields: boolean = false) {
        super(api);
        this.showHiddenFields = showHiddenFields;
    }
    
    async run(query: string) : Promise<string> {
        try {
            console.log(`*ListFieldsTool ${query}`);

            let config = this.api.getOrderConfig();
            if (config) {
                let result = [];
                for (const tableConfig of config.tables) {
                    let fields = this.fieldsToObject(tableConfig.fields);

                    if (this.showHiddenFields && fields.find(x=> x.id == 'createdAt') == null) {
                        fields = [
                        {
                            "id": "createdAt",
                            "type": "Date",
                            "caption": "hidden system field Unix time of creation",
                            "viewType": "Date",
                            "placeholder": "",
                            "autocomplete": false,
                            "visibilityOnList": "Subtitle"
                        },
                        ...fields];
                    }
                    result.push({
                        tableId: tableConfig.tableId,
                        tableName: tableConfig.tableName,
                        fields: fields
                    });
                }
                return JSON.stringify(result);
            }
            return "[]";
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }

    private fieldsToObject(fields: ICrmField[]): any[] {
        return _.map(fields, (x: ICrmField) => {
            let fieldData: any = {
                id: x.id, 
                caption: x.caption, 
                viewType: x.viewType
            } 
            if (x.options) {
                fieldData.options = x.options;
            }
            if (x.fields) {
                fieldData.fields = this.fieldsToObject(x.fields);
            }
            if (x.autocomplete) {
                fieldData.autocomplete = x.autocomplete;
            }
            if (x.placeholder) {
                fieldData.placeholder = x.placeholder;
            }

            return fieldData;
        });
    }
}


export class AddTableTool extends BaseTool {
    name: string = "add_table";
    description: string =
        "useful for when you need add empty table to CRM (fields must be added later). Input is a JSON in format { tableId: <target table unique id>, tableName: <user displayed table name>}";

    async run(query: string): Promise<string> {
        let task : {tableId: string, tableName: string};

        try {
            task = JSON.parse(query);
        }
        catch (error: any) {
            return "Exception: " + error.toString() + ". Input is incorrect JSON";
        }

        const validatorError = [];
        if (!task.tableId)
            validatorError.push('tableId is null or empty');
        if (!task.tableName)
            validatorError.push('tableName is null or empty');

        if (validatorError.length > 0)
            return 'Invalid input: ' + validatorError.join(', ');

        let config = this.api.getOrderConfig();
        if (!config)
            return "Error: configuration not loaded";

        if (config.tables.some(table => table.tableId == task.tableId)) {
            return `Error: table with id ${task.tableId} is already exist`;
        }

        const newTable: ITableConfig = {
            tableId: task.tableId,
            tableName: task.tableName,
            sortField: 'createdAt',
            sortDirection: CrmSortDirection.Desc,
            fields: [],
            filters: [],
        };
        const tables = [...config.tables, newTable]

        let newConfig: ITenantConfig = {...config, tables: tables}

        await this.api.saveOrderConfig(newConfig);
        return "Empty table successfully added.";
    }

    isAvailableAdd(config:ITenantConfig, tableId: string, field: ICrmField): string | undefined {
        const tableConfig = config.tables.find(x => x.tableId === tableId);

        if (tableConfig == null)
            return `Table with id '${tableId}' is absent`;
        
        if (tableConfig.fields.find(x => x.id.trim() === field.id)) {
            return `Field with id '${field.id}' is already exist in CRM`;
        }

        if (tableConfig.fields.find(x => x.caption.trim() === field.caption)) {
            return `Field with caption '${field.caption}' is already exist in CRM`;
        }

        return;
    }
}