import { db } from "@core/JsStore/idb";
import { IDbEntity } from "@core/JsStore/stores/shared/models/i-db-entity";
import { IDbRawData } from "@core/JsStore/stores/shared/models/i-db-raw-data";
import { ICrmField, CrmFieldViewType } from "@core/Models/autogenerated/tenantConfig.models";
import { Table } from "dexie";
import Logger from "js-logger";
import { DATA_TYPE, ITable, TColumns } from "jsstore";
import _ from "lodash";

export interface ISnapshotStore {
    get(entityId: string): Promise<IDbEntity | null>;
    bulkGet(entityIds: string[]): Promise<(IDbEntity|null)[]>;
    each(handler: (e: IDbEntity) => void): Promise<void>;
    remove(entityId: string): Promise<void>;
    bulkPut(bulkPutList : IDbEntity[]): Promise<void>;
    count(): Promise<number>;
    clear(): Promise<void>;
}

export class EntitySnapshotStore implements ISnapshotStore {
    name : string;

    constructor(name: string) {
        this.name = name;
    }

    public async get(entityId: string): Promise<IDbEntity | null> {
        const entityTable = this.getTable();
        return db.transaction('r', entityTable, async () => {
            const rawItem = await entityTable.get(entityId);
    
            if (!rawItem)
                return null;
    
            return this.mapIDbRawToIDbEntity(rawItem);
        });
    }

    public async bulkGet(entityIds: string[]): Promise<(IDbEntity | null)[]> {
        const entityTable = this.getTable();
        return db.transaction('r', entityTable, async () => {
            const items = await entityTable.bulkGet(entityIds) as IDbRawData[];
    
            let entities : (IDbEntity|null)[] = [];

            for (let e of items) {
                if (e)
                    entities.push(this.mapIDbRawToIDbEntity(e));
                else
                    entities.push(null);
            }
            return entities;
        });
    }

    public async each(handler: (e: IDbEntity) => void): Promise<void> {
        const entityTable = this.getTable();
        return db.transaction('r', entityTable, () => {
            try {
                return entityTable.each((rawItem, cursor) => {
                    if (rawItem != null)
                        handler(this.mapIDbRawToIDbEntity(rawItem));
                });
            } catch (err) {
                Logger.error("[EntitySnapshotStore] each handler exception", err);
                throw err;
            }
        });
    }

    public async remove(entityId: string): Promise<void> {
        const ordersTable = this.getTable();
        return db.transaction('rw', ordersTable, async () => {
            await ordersTable.where({id: entityId}).delete();
        });
    }

    public async bulkPut(bulkPutList : IDbEntity[]): Promise<void> {
        const entityTable = this.getTable();
        let raw = _.map(bulkPutList, this.mapDbEntityToDbRaw);
        await entityTable.bulkPut(raw); //понять нужен ли нам тут await
    }
    
    public async count(): Promise<number> {
        const table = this.getTable();

        return db.transaction('r', table, async () => {
            return await table.count();
        });
    }

    public async clear(): Promise<void> {
        Logger.debug(`Clearing database ${this.name}`);
        const table = this.getTable();
        await db.transaction('rw', table, async () => {
            await table.clear();
        });
    }

    private mapIDbRawToIDbEntity(dbRaw: IDbRawData): IDbEntity {
        const { id, lastEventNumber, lastEventTime, _keywords, ...restRawFields } = dbRaw;
    
        return {
            lastEventNumber: lastEventNumber as number,
            lastEventTime: lastEventTime as number,
            entityId: id as string,
            entityData: restRawFields,
            keywords: _keywords as (string[] | undefined),
        };
    }

    private mapDbEntityToDbRaw(dbEntity: IDbEntity): IDbRawData {
        return {
            ...dbEntity.entityData,
            lastEventNumber: dbEntity.lastEventNumber,
            lastEventTime: dbEntity.lastEventTime,
            id: dbEntity.entityId
        };
    }

    private getTable(): Table {
        return (db as any)[this.name] as Table;
    }

    public static getSchemas(name: string, fields: ICrmField[]) {
        const schema = EntitySnapshotStore.mapOrdersConfigToIDataBase(name, fields);
        return [schema];
    }

    static mapDataType(viewType: CrmFieldViewType): string {
        switch (viewType) {
            case CrmFieldViewType.YesNo:
                return DATA_TYPE.Boolean;
            case CrmFieldViewType.Decimal:
            case CrmFieldViewType.Date:
                return DATA_TYPE.Number;
            case CrmFieldViewType.Time:
            case CrmFieldViewType.Phone:
            case CrmFieldViewType.String:
            case CrmFieldViewType.MultiString:
                return DATA_TYPE.String;
            case CrmFieldViewType.Timeline:    
            case CrmFieldViewType.Comments:
            case CrmFieldViewType.Array:
            case CrmFieldViewType.Tags:
                return DATA_TYPE.Array;
            default:
                return DATA_TYPE.String;
        }
    }

    static mapOrdersConfigToIDataBase(name: string, fields: ICrmField[]): ITable {
        const columns: TColumns = {};
    
        // for (let field of fields) {
        //     if (field.id === 'id' || field.id === 'createdAt')
        //         continue;
    
        //     const column: IColumnOption = {
        //         dataType: EntitySnapshotStore.mapDataType(field.type),
        //     };
    
        //     columns[field.id] = column;
        // }
    
        // системные поля
    
        columns['id'] = {
            dataType: DATA_TYPE.String,
            notNull: true,
            primaryKey: true,
            unique: true
        };
    
        // columns['createdAt'] = {
        //     dataType: DATA_TYPE.Number,
        // };
    
        // columns['lastEventNumber'] = {
        //     dataType: DATA_TYPE.Number,
        // };
    
        return {
            name: name,
            columns
        };
    }
}