import { useRef, useState } from 'react';
import { ReactComponent as FunnelIcon } from '@assets/Icons/funnel2-icon.svg';
import { ReactComponent as FunnelFullIcon } from "@assets/Icons/funnel-full-icon.svg";
import styles from "./CrmGridSimpleFilterButton.module.scss";
import { CrmGridAddFilterPanel } from '../CrmGridAddFilterPanel/CrmGridAddFilterPanel';
import { ICrmField, CrmFieldViewType } from '@core/Models/autogenerated/tenantConfig.models';

export interface ICrmGridSimpleFilterButtonProps {
    query?: any;
    field: ICrmField;
    onChangedSimpleQuery: (query: any) => void;
    onResetSimpleQuery: () => void;
}

export function CrmGridSimpleFilterButton(props: ICrmGridSimpleFilterButtonProps) {
    const [showForm, setShowForm] = useState<boolean>(false);
    const targetRef = useRef<any>();

    const handleShowForm = () => {
        setShowForm(prev => !prev);
    }

    if (!allowedViewTypes.includes(props.field.viewType)) {
        return <></>;
    }

    return <>
        {props.query == null
            ? <FunnelIcon ref={targetRef} className={styles.funnelIcon} onClick={handleShowForm} />
            : <FunnelFullIcon ref={targetRef} className={styles.funnelIconFull} onClick={handleShowForm} />
        }
        {showForm &&
            <CrmGridAddFilterPanel
                isVisible={showForm}
                onHide={() => setShowForm(false)}
                targetRef={targetRef}
                field={props.field}
                query={props.query}
                onChangedSimpleQuery={props.onChangedSimpleQuery}
                onResetSimpleQuery={props.onResetSimpleQuery}
            />
        }
    </>;
}

const allowedViewTypes = [
    CrmFieldViewType.String,
    CrmFieldViewType.Phone,
    CrmFieldViewType.MultiString,
    CrmFieldViewType.Date,
    CrmFieldViewType.Decimal,
    CrmFieldViewType.Combobox,
    CrmFieldViewType.Time,
    CrmFieldViewType.YesNo,
    CrmFieldViewType.Url,
    CrmFieldViewType.Array,
    CrmFieldViewType.Tags,
];