import { DateTime } from 'luxon';
import styles from './FormattedDate.module.scss';
import _ from 'lodash';
import { CSSProperties } from 'react';

export interface IFormattedDateProps {
    value: any;
    style?: CSSProperties;
}

export function FormattedDate(props: IFormattedDateProps) {
    const dateStr = getDateString(props.value);
    return (<span className={styles.host} style={props.style}>{dateStr}</span>);
}

export function getDateString(value: any) {
    const parsed = parseFloat(value);

    if (!_.isNumber(parsed) || _.isNaN(parsed)) {
        return value;
    }

    return DateTime.fromSeconds(parsed).toLocaleString();
}
