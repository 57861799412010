import { useEffect, useState } from "react";
import { ICrmField, CrmFieldViewType } from "@core/Models/autogenerated/tenantConfig.models";
import { CrmGridAddBooleanFilterDialog } from "../CrmGridAddBooleanFilterDialog/CrmGridAddBooleanFilterDialog";
import { CrmGridAddComboboxFilterDialog } from "../CrmGridAddComboboxFilterDialog/CrmGridAddComboboxFilterDialog";
import { CrmGridAddDateFilterDialog } from "../CrmGridAddDateFilterDialog/CrmGridAddDateFilterDialog";
import { CrmGridAddDecimalFilterDialog } from "../CrmGridAddDecimalFilterDialog/CrmGridAddDecimalFilterDialog";
import { CrmGridAddStringFilterDialog } from "../CrmGridAddStringFilterDialog/CrmGridAddStringFilterDialog";
import { CrmGridAddTimeFilterDialog } from "../CrmGridAddTimeFilterDialog/CrmGridAddTimeFilterDialog";
import _ from "lodash";
import { CrmGridAddTagsFilterDialog } from "../CrmGridAddTagsFilterDialog/CrmGridAddTagsFilterDialog";

interface IArrayFilterQuery {
    $elemMatch: { [fieldId: string]: any };
}

export interface ICrmGridAddArrayFilterDialogProps {
    field: ICrmField;
    query: IArrayFilterQuery | null;
    onChanged: (query: IArrayFilterQuery | null) => void;
    setIsValid: (isValid: boolean) => void;
    onEnter?: () => void;
}

export function CrmGridAddArrayFilterDialog(props: ICrmGridAddArrayFilterDialogProps) {
    const [currQuery, setCurrQuery] = useState<IArrayFilterQuery | null>(props.query);
    const [invalidFields, setInvalidFields] = useState<ICrmField[]>([]);

    const onChanged = (fieldId: string, query: any) => {
        let newQuery = currQuery;

        if (query == null) {
            delete newQuery?.$elemMatch[fieldId];

            if (_.isEmpty(newQuery?.$elemMatch)) {
                newQuery = null;
            }
        }
        else {
            newQuery = newQuery || { $elemMatch: {} };
            newQuery.$elemMatch[fieldId] = query;
        }

        setCurrQuery(newQuery);
        props.onChanged(newQuery);
    }

    const onValidChanged = (field: ICrmField, isValid: boolean) => {
        if (isValid) {
            setInvalidFields(prev => prev.filter(x => x.id != field.id));
        }
        else {
            setInvalidFields(prev => {
                if (prev.find(x => x.id == field.id) == null) {
                    return [...prev, field];
                }

                return prev;
            });
        }
    };

    useEffect(() => {
        props.setIsValid(invalidFields.length == 0);
    }, [invalidFields.length == 0]);

    const onEnter = () => {
        props.onEnter?.();
    };

    const render = (field: ICrmField, index: number) => {
        const commonProps = {
            field,
            onChanged: (query: any) => onChanged(field.id, query),
            query: currQuery?.$elemMatch?.[field.id],
            onEnter,
            autoFocus: index == 0,
            setIsValid: (isValid: boolean) => onValidChanged(field, isValid),
        };

        switch (field.viewType) {
            case CrmFieldViewType.String:
            case CrmFieldViewType.MultiString:
            case CrmFieldViewType.Url:
            case CrmFieldViewType.Phone:
                return <CrmGridAddStringFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Decimal:
                return <CrmGridAddDecimalFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Date:
                return <CrmGridAddDateFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Time:
                return <CrmGridAddTimeFilterDialog {...commonProps}/>;
            case CrmFieldViewType.YesNo:
                return <CrmGridAddBooleanFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Combobox:
                return <CrmGridAddComboboxFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Tags:
                return <CrmGridAddTagsFilterDialog {...commonProps}/>;
        }
    }

    return <>{props.field.fields?.filter(x => !x.hidden)?.map((field, index) =>
        <div key={field.id}>
            <span>{field.caption}</span>
            {render(field, index)}
        </div>
    )}</>;
}