import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";
import { ICrmUserInfo } from "@core/Models/autogenerated/user.models";
import { CrmFieldDefault } from "@core/Models/tenantConfig.models";
import { DateTime } from "luxon";

export function getDefaultValue(field: ICrmField, userInfo: ICrmUserInfo) {
    if (field.default == null) {
        return null;
    }

    switch (field.default) {
        case CrmFieldDefault.User:
            return userInfo.login;

        case CrmFieldDefault.Date:
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            return Math.floor(currentDate.getTime() / 1000);

        case CrmFieldDefault.Time:
            return DateTime.now().toFormat("HH:mm");

        default:
            return field.default;
    }
}