import type { ICrmArrayElement } from '@core/Models/i-array-element';
import type { ICrmArrayOperationAdd, ICrmArrayOperationMove, ICrmArrayOperationRemove } from '@core/Models/i-crm-array-operation-events';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export function CalculateArrayModifications(oldValues: ICrmArrayElement[], newValues: ICrmArrayElement[]) {
    oldValues = oldValues ?? [];
    newValues = newValues ?? [];

    throwIfInvalidChanges(oldValues, newValues);

    let toAdd : Record<string, ICrmArrayOperationAdd> = {};
    let toRemove : Record<string,ICrmArrayOperationRemove> = {};
    let toMove : Record<string, ICrmArrayOperationMove> = {};


    for(let i = 0; i < Math.max(oldValues.length, newValues.length); i++) {
        if (i >= oldValues.length) {
            toAdd[newValues[i].id] = { id: newValues[i].id, pos:i, value:newValues[i] }
        }
        if (i >= newValues.length) {
            toRemove[oldValues[i].id] = { id: oldValues[i].id }
        }
        if (i < oldValues.length && i < newValues.length && oldValues[i].id != newValues[i].id) {
            toAdd[newValues[i].id] = { id: newValues[i].id, pos:i, value:newValues[i] }
            toRemove[oldValues[i].id] = { id: oldValues[i].id }
        }
    }

    for (let id of Object.keys(toAdd)) {
        if (id in toRemove) {
            toMove[id] = { "id": id, "pos": toAdd[id].pos }
            delete toRemove[id]
            delete toAdd[id];
        }
    }

    return { 
        add:Object.values(toAdd), 
        remove:Object.values(toRemove),
        move:Object.values(toMove)
    };
}

function throwIfInvalidChanges(oldValues: ICrmArrayElement[], newValues: ICrmArrayElement[]) {
    for (const newValue of newValues) {
        const oldValue = oldValues?.find(x => x.id === newValue.id);
        if (oldValue && !_.isEqual(oldValue, newValue)) {
            throw Error("Invalid array update: values with the same id are different");
        }
    }
}

export function generateNewArrayIds(oldValues: ICrmArrayElement[], newValues: ICrmArrayElement[]) {
    const result = _.cloneDeep(newValues);

    for (const newValue of result) {
        const oldValue = oldValues?.find(x => x.id === newValue.id);
        if (oldValue && !_.isEqual(oldValue, newValue)) {
            newValue.id = uuidv4();
        }
    }

    return result;
}

export function ApplyArrayModifications(values : ICrmArrayElement[]
    , add : ICrmArrayOperationAdd[]
    , remove : ICrmArrayOperationRemove[]
    , move : ICrmArrayOperationMove[]): ICrmArrayElement[] {

    values = [...values ?? []];
    add = [...add ?? []];
    remove = [...remove ?? []];

    //move=remove+add. unpack it
    for (let e of move ?? []) {
        const from = values.findIndex(x => x.id === e.id);
        if (from >= 0) {
            let to = e.pos;
            const item = values[from];
            remove.push({id: e.id});
            add.push({id:e.id, pos:to, value:item});
        }
    }

    for (let e of remove) {
        const idx = values.findIndex(x => x.id === e.id);
        if (idx >= 0) {
            values.splice(idx, 1);
        }
    }

    const addSorted = _.sortBy(add, x=> x.pos);
    for (let e of addSorted) {
        const idx = values.findIndex(x => x != null && e.value != null && x.id === e.value.id);
        if (idx >= 0)
            continue;
            
        let to = e.pos;
        if (to < 0)
            to = values.length + 1 + to;
        if (to >= values.length)
            to = values.length;
        values.splice(to, 0, e.value);
    }

    return values;
}
