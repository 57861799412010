import { Route, Routes } from 'react-router-dom';
import { CreateOrderPage } from './CreateOrderPage';
import { EditOrderPage } from './EditOrderPage';
import { ListOrdersPage } from './ListOrdersPage';
import { entityEditPathMask } from '@core/Constants/route-paths';

export function OrdersPage(props: {tableId: string}) {
    return (
        <Routes>
            <Route path="" element={<ListOrdersPage tableId={props.tableId}/>} />
            <Route path="create/*" element={<CreateOrderPage  tableId={props.tableId}/>} />
            <Route path={entityEditPathMask} element={<EditOrderPage  tableId={props.tableId}/>} />
        </Routes>
    );
}
