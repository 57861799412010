import { ICrmCellInputEditorsProps } from "@core/VisualComponents/CrmGridView/CrmCellInputEditors/CrmCellInputEditors";
import React, { MutableRefObject } from "react";
import {IEntityData} from "@core/Models/i-entity";
import { ComponentDesc } from "./i-plugin-contract";
import { component } from "@core/Plugins/pluginManager";

import { IOrdersListViewItemValueProps } from "@core/VisualComponents/OrdersListViewItemValue/OrdersListViewItemValue";
import { IOrderFieldEditorProps } from "@core/VisualComponents/OrderFieldEditor/OrderFieldEditor";
import { IContextMenuProps } from "@core/VisualComponents/ContextMenu/ContextMenu";
import { IContextMenuItemProps } from "@core/VisualComponents/ContextMenu/ContextMenuItem/ContextMenuItem";
import { IConfirmDeleteProps } from "@core/VisualComponents/ConfirmDelete/ConfirmDelete";
import { ICrmGridRowProps, ICrmGridViewProps } from "@core/VisualComponents/CrmGridView/CrmGridView";
import { ISearchBarProps } from "src/App/Pages/OrdersPage/Components/SearchBar";
import { IOrderGridViewEditorsProps } from "src/App/Pages/OrdersPage/OrdersView/OrderGridView/OrderGridViewEditors/OrderGridViewEditors";
import { IDateInputProps } from "@core/VisualComponents/Inputs/DateInput/DateInput";
import { IStringInputProps } from "@core/VisualComponents/Inputs/StringInput/StringInput";
import { IArrayEditorInnerProps } from "@core/VisualComponents/ArrayComponents/ArrayEditorInner/ArrayEditorInner";
import { IArrayEditorModalProps } from "@core/VisualComponents/ArrayComponents/ArrayEditorModal/ArrayEditorModal";
import { IArrayValueRendererProps, IArrayValuesViewerProps } from "@core/VisualComponents/ArrayComponents/ArrayValuesViewer/ArrayValuesViewer";
import { ICrmCellInternalProps, ICrmCellInternalSpanProps } from "@core/VisualComponents/CrmGridView/CrmCellInternal/CrmCellInternal";
import { IOrdersListViewItemProps } from "src/App/Pages/OrdersPage/OrdersView/OrdersListView/OrdersListViewItem/OrdersListViewItem";
import { ICallButtonProps as ICallButtonProps, IPhoneInputProps } from "@core/VisualComponents/Inputs/PhoneInput/PhoneInput";
import { ICrmEditOverlayProps } from "@core/VisualComponents/CrmGridView/CrmCellInputEditors/CrmEditOverlay/CrmEditOverlay";
import { IModalWindowProps } from "@core/VisualComponents/ModalWindow/ModalWindow";
import { ITimeInputProps } from "@core/VisualComponents/Inputs/TimeInput/TimeInput";
import { ICheckboxInputProps } from "@core/VisualComponents/Inputs/CheckboxInput/CheckboxInput";
import { IComboboxInputProps } from "@core/VisualComponents/Inputs/ComboboxInput/ComboboxInput";
import { IDecimalInputProps } from "@core/VisualComponents/Inputs/DecimalInput/DecimalInput";
import { IUrlInputProps } from "@core/VisualComponents/Inputs/UrlInput/UrlInput";
import { IPrimaryButtonProps } from "@core/VisualComponents/Buttons/PrimaryButton";
import { ISecondaryButtonProps } from "@core/VisualComponents/Buttons/SecondaryButton";
import { ICommentViewerProps } from "@core/VisualComponents/CrmGridView/CrmCellInternal/CommentViewer/CommentViewer";
import { ICommentsMessageProps } from "@core/VisualComponents/Comments/CommentsMessage";
import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";
import { ITagsInputProps } from "@core/VisualComponents/Inputs/TagsInput/TagsInput";

export interface IContextMenuClickedArgs {
    e: MouseEvent;
    entityData: IEntityData;
    cellRef: MutableRefObject<any>;
}

export interface ICellClickedArgs {
    e: React.MouseEvent;
    props: ICrmCellInternalProps;
    entityData: IEntityData;
    cellRef: MutableRefObject<any>;
}

export interface IMousePosition {
    x: number;
    y: number;
}

export interface IOnShowEditorReq {
    key: string;
    position: IMousePosition;
    initialValue: unknown;
    entityData: IEntityData;
    tableId: string;
    entityId: string;
    targetRef: MutableRefObject<any>;
    field: ICrmField;
    autocompleteValues: string[];
}

export interface ICrmCellInputEditors {
    showEditor: (tableId: string, entityId: string, entityData: IEntityData, field: ICrmField, position: IMousePosition, targetRef?: React.MutableRefObject<any>) => void;
    hideEditor?: () => void;
}

//core UI components description that can be used and overrided
export const coreUiComponentDescriptions = {
    CrmGridView: {
        componentName: "CrmGridView"
    } as ComponentDesc<ICrmGridViewProps>,
    CrmCellInternal: {
        componentName: "CrmCellInternal",
    } as ComponentDesc<ICrmCellInternalProps>,
    CrmGridRow: {
        componentName: "CrmGridRow"
    } as ComponentDesc<ICrmGridRowProps>,
    CrmCellInternalSpan: {
        componentName: "CrmCellInternalSpan",
    } as ComponentDesc<ICrmCellInternalSpanProps>,
    SearchBar: {
        componentName: "SearchBar",
    } as ComponentDesc<ISearchBarProps>,
    OrderGridViewEditors: {
        componentName: "OrderGridViewEditors"
    } as ComponentDesc<IOrderGridViewEditorsProps>,
    CrmCellInputEditors: {
        componentName: "CrmCellInputEditors"
    } as ComponentDesc<ICrmCellInputEditorsProps>,
    OrdersListViewItem: {
        componentName: "OrdersListViewItem"
    } as ComponentDesc<IOrdersListViewItemProps>,
    OrdersListViewItemValue: {
        componentName: "OrdersListViewItemValue"
    } as ComponentDesc<IOrdersListViewItemValueProps>,
    OrderFieldEditor: {
        componentName: "OrderFieldEditor"
    } as ComponentDesc<IOrderFieldEditorProps>,
    ContextMenu: {
        componentName: "ContextMenu"
    } as ComponentDesc<IContextMenuProps>,
    ContextMenuItem: {
        componentName: "ContextMenuItem"
    } as ComponentDesc<IContextMenuItemProps>,
    ConfirmDelete: {
        componentName: "ConfirmDelete"
    } as ComponentDesc<IConfirmDeleteProps>,
    DateInput: {
        componentName: "DateInput"
    } as ComponentDesc<IDateInputProps>,
    PhoneInput: {
        componentName: "PhoneInput"
    } as ComponentDesc<IPhoneInputProps>,
    StringInput: {
        componentName: "StringInput"
    } as ComponentDesc<IStringInputProps>,
    TimeInput: {
        componentName: "TimeInput"
    } as ComponentDesc<ITimeInputProps>,
    UrlInput: {
        componentName: "UrlInput"
    } as ComponentDesc<IUrlInputProps>,
    DecimalInput: {
        componentName: "DecimalInput"
    } as ComponentDesc<IDecimalInputProps>,
    ComboboxInput: {
        componentName: "ComboboxInput"
    } as ComponentDesc<IComboboxInputProps>,
    CheckboxInput: {
        componentName: "CheckboxInput"
    } as ComponentDesc<ICheckboxInputProps>,
    TagsInput: {
        componentName: "TagsInput"
    } as ComponentDesc<ITagsInputProps>,
    ArrayEditorInner: {
        componentName: "ArrayEditorInner"
    } as ComponentDesc<IArrayEditorInnerProps>,
    ArrayEditorModal: {
        componentName: "ArrayEditorModal"
    } as ComponentDesc<IArrayEditorModalProps>,
    ArrayValuesViewer: {
        componentName: "ArrayValuesViewer"
    } as ComponentDesc<IArrayValuesViewerProps>,
    ArrayValueRenderer: {
        componentName: "ArrayValueRenderer"
    } as ComponentDesc<IArrayValueRendererProps>,
    CallButton: {
        componentName: "CallButton"
    } as ComponentDesc<ICallButtonProps>,
    CrmEditOverlay: {
        componentName: "CrmEditOverlay"
    } as ComponentDesc<ICrmEditOverlayProps>,
    ModalWindow: {
        componentName: "ModalWindow"
    } as ComponentDesc<IModalWindowProps>,
    PrimaryButton: {
        componentName: "PrimaryButton"
    } as ComponentDesc<IPrimaryButtonProps>,
    SecondaryButton: {
        componentName: "SecondaryButton"
    } as ComponentDesc<ISecondaryButtonProps>,
    CommentViewer: {
        componentName: "CommentViewer"
    } as ComponentDesc<ICommentViewerProps>,
    CommentsMessage: {
        componentName: "CommentsMessage"
    } as ComponentDesc<ICommentsMessageProps>,
}

export class CoreUiComponents {
    get CrmGridView() { return component(coreUiComponentDescriptions.CrmGridView); }
    get CrmCellInternal() { return component(coreUiComponentDescriptions.CrmCellInternal); }
    get CrmGridRow() { return component(coreUiComponentDescriptions.CrmGridRow); }
    get CrmCellInternalSpan() { return component(coreUiComponentDescriptions.CrmCellInternalSpan); }
    get SearchBar() { return component(coreUiComponentDescriptions.SearchBar); }
    get OrderGridViewEditors() { return component(coreUiComponentDescriptions.OrderGridViewEditors); }
    get CrmCellInputEditors() { return component(coreUiComponentDescriptions.CrmCellInputEditors); }
    get OrdersListViewItem() { return component(coreUiComponentDescriptions.OrdersListViewItem); }
    get OrdersListViewItemValue() { return component(coreUiComponentDescriptions.OrdersListViewItemValue); }
    get OrderFieldEditor() { return component(coreUiComponentDescriptions.OrderFieldEditor); }
    get ContextMenu() { return component(coreUiComponentDescriptions.ContextMenu); }
    get ContextMenuItem() { return component(coreUiComponentDescriptions.ContextMenuItem); }
    get ConfirmDelete() { return component(coreUiComponentDescriptions.ConfirmDelete); }
    get DateInput() { return component(coreUiComponentDescriptions.DateInput); }
    get PhoneInput() { return component(coreUiComponentDescriptions.PhoneInput); }
    get StringInput() { return component(coreUiComponentDescriptions.StringInput); }
    get TimeInput() { return component(coreUiComponentDescriptions.TimeInput); }
    get UrlInput() { return component(coreUiComponentDescriptions.UrlInput); }
    get DecimalInput() { return component(coreUiComponentDescriptions.DecimalInput); }
    get ComboboxInput() { return component(coreUiComponentDescriptions.ComboboxInput); }
    get CheckboxInput() { return component(coreUiComponentDescriptions.CheckboxInput); }
    get TagsInput() { return component(coreUiComponentDescriptions.TagsInput); }
    get ArrayEditorInner() { return component(coreUiComponentDescriptions.ArrayEditorInner); }
    get ArrayEditorModal() { return component(coreUiComponentDescriptions.ArrayEditorModal); }
    get ArrayValuesViewer() { return component(coreUiComponentDescriptions.ArrayValuesViewer); }
    get ArrayValueRenderer() { return component(coreUiComponentDescriptions.ArrayValueRenderer); }
    get CallButton() { return component(coreUiComponentDescriptions.CallButton); }
    get CrmEditOverlay() { return component(coreUiComponentDescriptions.CrmEditOverlay); }
    get ModalWindow() { return component(coreUiComponentDescriptions.ModalWindow); }
    get PrimaryButton() { return component(coreUiComponentDescriptions.PrimaryButton); }
    get SecondaryButton() { return component(coreUiComponentDescriptions.SecondaryButton); }
    get CommentViewer() { return component(coreUiComponentDescriptions.CommentViewer); }
    get CommentsMessage() { return component(coreUiComponentDescriptions.CommentsMessage); }
}
