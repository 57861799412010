import { MutableRefObject, useEffect, useRef, useState } from "react";
import Overlay from "react-overlays/esm/Overlay";
import styles from "./CrmGridAddFilterPanel.module.scss";
import { SecondaryButton } from "@core/VisualComponents/Buttons/SecondaryButton";
import { PrimaryButton } from "@core/VisualComponents/Buttons/PrimaryButton";
import { ReactComponent as CloseBtnSvg } from '@assets/Icons/close-button-icon.svg';
import { t } from "i18next";
import { CrmGridAddStringFilterDialog } from "./CrmGridAddStringFilterDialog/CrmGridAddStringFilterDialog";
import { CrmGridAddDecimalFilterDialog } from "./CrmGridAddDecimalFilterDialog/CrmGridAddDecimalFilterDialog";
import { CrmGridAddBooleanFilterDialog } from "./CrmGridAddBooleanFilterDialog/CrmGridAddBooleanFilterDialog";
import { CrmGridAddComboboxFilterDialog } from "./CrmGridAddComboboxFilterDialog/CrmGridAddComboboxFilterDialog";
import { CrmGridAddDateFilterDialog } from "./CrmGridAddDateFilterDialog/CrmGridAddDateFilterDialog";
import { CrmGridAddTimeFilterDialog } from "./CrmGridAddTimeFilterDialog/CrmGridAddTimeFilterDialog";
import { ICrmField, CrmFieldViewType } from "@core/Models/autogenerated/tenantConfig.models";
import { CrmGridAddArrayFilterDialog } from "./CrmGridAddArrayFilterDialog/CrmGridAddArrayFilterDialog";
import { CrmGridAddTagsFilterDialog } from "./CrmGridAddTagsFilterDialog/CrmGridAddTagsFilterDialog";

export interface ICrmGridAddFilterPanelProps {
    isVisible: boolean;
    onHide: () => void;
    targetRef: MutableRefObject<any>;
    field: ICrmField;
    query?: any;
    onChangedSimpleQuery: (query: any) => void;
    onResetSimpleQuery: () => void;
}

export function CrmGridAddFilterPanel(compProps: ICrmGridAddFilterPanelProps) {
    const [isValid, setIsValid] = useState<boolean>(true);

    const queryRef = useRef<any>(compProps.query ?? null);

    const onReset = () => {
        queryRef.current = null;
        compProps.onResetSimpleQuery();
        compProps.onHide();
    }

    const onSave = () => {
        compProps.onHide();
        if (queryRef.current == null) {
            compProps.onResetSimpleQuery();
        }
        else {
            compProps.onChangedSimpleQuery(queryRef.current);
        }
    }

    const onChanged = (query: any) => {
        queryRef.current = query;
    }

    const keydownHandler = (event: KeyboardEvent) => {
        if (!compProps.isVisible) {
            return;
        }

        switch (event.key) {
            case "Escape":
                compProps.onHide();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const commonProps = {
        field: compProps.field,
        onChanged,
        query: queryRef.current,
        onEnter: onSave,
        autoFocus: true,
        setIsValid,
    };

    const render = () => {
        switch (compProps.field.viewType) {
            case CrmFieldViewType.String:
            case CrmFieldViewType.MultiString:
            case CrmFieldViewType.Url:
            case CrmFieldViewType.Phone:
                return <CrmGridAddStringFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Decimal:
                return <CrmGridAddDecimalFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Date:
                return <CrmGridAddDateFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Time:
                return <CrmGridAddTimeFilterDialog {...commonProps}/>;
            case CrmFieldViewType.YesNo:
                return <CrmGridAddBooleanFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Combobox:
                return <CrmGridAddComboboxFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Array:
                return <CrmGridAddArrayFilterDialog {...commonProps}/>;
            case CrmFieldViewType.Tags:
                return <CrmGridAddTagsFilterDialog {...commonProps}/>;
        }
    }

    return (<Overlay
        show={compProps.isVisible}
        flip={true}
        placement={'bottom-start'}
        rootClose={true}
        onHide={() => compProps.onHide()}
        target={compProps.targetRef}
        popperConfig={popperConfig}
    >
        {({ show, props, arrowProps, placement }) => 
            <div {...props} className={styles.host}>
                <div className={styles.component}>
                    {render()}
                    
                    <div className={styles.buttonsPanel}>
                        <div className={styles.spring}></div>
                        <SecondaryButton onClick={onReset}>
                            {t("reset")}
                        </SecondaryButton>
                        <PrimaryButton onClick={onSave} disabled={!isValid}>
                            {t("apply")}
                        </PrimaryButton>
                    </div>
                    <button type="button"
                            className={styles.closeButton}
                            onClick={compProps.onHide}>
                        <CloseBtnSvg />
                    </button>
                </div>
            </div>
        }
    </Overlay>)
}

const popperConfig = {
    modifiers: [
        {
            name: 'preventOverflow',
            options: {
                boundary: 'viewport',
            },
        },
        {
            name: 'flip',
            options: {
                fallbackPlacements: ['left', 'right', 'bottom'],
            },
        },
    ]
};