import {DateTime} from 'luxon';

export function toUtcDateWithoutTime(date: Date): number {
    // if(!date)
    //     return null;

    // const offsetInMinutes = date.getTimezoneOffset();
    // return DateTime
    //     .fromJSDate(date)
    //     .plus({minute: -offsetInMinutes})
    //     .startOf('day')
    //     .toUnixInteger();

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const startOfDayUTC = new Date(Date.UTC(year, month, day));
    
    return Math.floor(startOfDayUTC.getTime() / 1000);
}
