import { v4 as uuidv4 } from 'uuid';
import { IEntity, IEntityData } from "@core/Models/i-entity";
import { IEventSouringQueryViewState } from "./Implementation/LiveQueryView";
import { IQuerySpecification } from "./Implementation/LiveQuery";
import { IShortQueueStore, ShortQueueStore } from "./Implementation/ShortQueueStore";
import { ICrmOperationEventDecoded, ICrmOperationEventDecodedWithLastEventInfo } from "@core/Models/i-crm-operation-event-decoded";
import { CalculateArrayModifications } from './Implementation/array_operations';
import { ICrmArrayElement } from '@core/Models/i-array-element';
import { ICrmArrayUpdateEvent } from '@core/Models/i-crm-array-operation-events';
import Logger from 'js-logger';
import { IEventStreamEvent } from './EventRemoteStream';
import { ICrmOperationEvent } from '@core/Models/autogenerated/operation.models';
import { BulkUpdateStatus, IBulkUpdateResult } from './BulkUpdateApi';
import { QueryVars } from '@core/Utils/MongoQueryParser';

export interface IEventSourcingStoreReactClient {
    tableId: string;
    
    get(entityId: string): Promise<IEntity | null>;
    bulkGet(entityIds: string[]): Promise<(IEntity | null)[]>;
    add(entity : IEntity) : Promise<void>;
    update(id: string, entityChanges: IEntityData): Promise<void>;
    multiUpdate(idsToChange: string[], callback: (entity: IEntity) => IEntityData): Promise<IBulkUpdateResult>;
    updateArray(entityId: string, fieldId: string, oldValues: ICrmArrayElement[], newValues: ICrmArrayElement[]): Promise<void>;
    delete(entityId: string): Promise<void>;
    count(): Promise<number>;

    useGet(entityId: string): IEntity | null;
    useQuerySubscribe(query: IQuerySpecification | null, vars: Map<string, any>, skip: number, limit: number, sortAscending: boolean
        , onArrayUpdate: (entities: IEntity[]) => void
        , onIndividualUpdate: (entities: IEntity[]) => void
        , onNewState: (state: IEventSouringQueryViewState) => void): void
    queryIds(query: IQuerySpecification, queryVars: QueryVars): Promise<string[]>;
    queryEntities(query: IQuerySpecification, queryVars: QueryVars, limit?: number): Promise<IEntity[]>;

    process(abortController: AbortController) : Promise<void>;

    preloadLiveQuerieMonitors(queries: IQuerySpecification[], queryVars: QueryVars): void;
    disposeLiveQueryMonitorByQuery(query: IQuerySpecification, queryVars: QueryVars): void
    dispose():void;
}

export abstract class EventSourcingClientBase implements IEventSourcingStoreReactClient {
    tableId: string;
    clientInstance: string;
    tenant: string;

     //this queue added for durability. for example when user modify data but database is busy.
     //in such case device may be turned off before event propagated
    shortEventQueue : IShortQueueStore<IEventStreamEvent>;

    constructor(
        tableId: string
        , tenant: string
        , clientInstance: string
    ) {
        this.tableId = tableId;
        this.tenant = tenant;
        this.clientInstance = clientInstance;

        this.shortEventQueue = new ShortQueueStore<IEventStreamEvent>(
            tableId + '_short', 
            ({event}) => this.propagateEvent(event)
        );
        
        this.clientInstance = clientInstance;
        this.tenant = tenant;
    }
    public abstract get(entityId: string): Promise<IEntity | null>;
    public abstract bulkGet(entityIds: string[]): Promise<(IEntity | null)[]>;
    public abstract useGet(entityId: string): IEntity | null 
    public abstract useQuerySubscribe(query: IQuerySpecification | null, vars: Map<string, any>, skip: number, limit: number, sortAscending: boolean, onArrayUpdate: (entities: IEntity[]) => void, onIndividualUpdate: (entities: IEntity[]) => void, onNewState: (state: IEventSouringQueryViewState) => void): void
    public abstract count(): Promise<number>;
        
    public abstract preloadLiveQuerieMonitors(queries: IQuerySpecification[], queryVars: QueryVars): void;
    public abstract disposeLiveQueryMonitorByQuery(query: IQuerySpecification, queryVars: QueryVars): void;
    public abstract dispose(): void;

    protected abstract propagateEvent(event: ICrmOperationEvent) : Promise<void>;
    protected abstract propagateEvents(events: ICrmOperationEventDecodedWithLastEventInfo[]): Promise<IBulkUpdateResult>;

    public abstract queryIds(query: IQuerySpecification, queryVars: QueryVars): Promise<string[]>;
    public abstract queryEntities(query: IQuerySpecification, queryVars: QueryVars, limit?: number): Promise<IEntity[]>;

    public async process(abortController: AbortController) : Promise<void> {
        try {
            await this.shortEventQueue.process();
        }
        catch(err: any) {
            Logger.error("Error at EventSourcingStore.shortEventQueue.process()", err);
        }

    }

    getClientInstance() {
        return {id: this.clientInstance, tenant:"" };
    }
    
    public add(entity : IEntity) : Promise<void> {
        let event = {
            type: 'AddOrder',
            tableId: this.tableId,
            entityId: entity.id,
            data: JSON.stringify(entity.data),
            createdAt: new Date().toISOString(),
            creationContext: JSON.stringify({localId: uuidv4(), clientInstance: this.getClientInstance().id}),
            decodedData: entity.data,
        } as ICrmOperationEventDecoded;

        return this.shortEventQueue.enqueue({event});
    }

    public update(id: string, entityChanges: IEntityData): Promise<void> {
        let event = {
            type: 'UpdateOrder',
            tableId: this.tableId,
            entityId: id,
            data: JSON.stringify(entityChanges),
            createdAt: new Date().toISOString(),
            creationContext: JSON.stringify({localId: uuidv4(), clientInstance: this.getClientInstance().id}),
            decodedData: entityChanges,
        } as ICrmOperationEventDecoded;

        return this.shortEventQueue.enqueue({event});
    }

    private bulkUpdate(changes: {entity: IEntity, entityChanges: IEntityData}[]): Promise<IBulkUpdateResult> {
        const events: ICrmOperationEventDecodedWithLastEventInfo[] = changes.map(({entity, entityChanges}) => ({
            type: 'UpdateOrder',
            tableId: this.tableId,
            entityId: entity.id,
            data: JSON.stringify(entityChanges),
            createdAt: new Date().toISOString(),
            creationContext: JSON.stringify({localId: uuidv4(), clientInstance: this.getClientInstance().id}),
            decodedData: entityChanges,
            lastEventNumber: entity._lastEventNumber,
            lastEventTime: entity._lastEventTime,
        } as any));

        return this.propagateEvents(events);
    }

    private batchIds(ids: string[], batchSize: number): string[][] {
        const result: string[][] = [];
        for (let i = 0; i < ids.length; i += batchSize) {
            const chunk = ids.slice(i, i + batchSize);
            result.push(chunk);
        }
        return result;
    }

    public async multiUpdate(idsToChange: string[], callback: (entity: IEntity) => IEntityData): Promise<IBulkUpdateResult> {
        const batchSize = 100;
        let result: IBulkUpdateResult = {
            status: BulkUpdateStatus.Done,
            numberOfCompleted: 0,
        }

        for (const batch of this.batchIds(idsToChange, batchSize)) {
            let entityChangesBatch: {entity: IEntity, entityChanges: IEntityData}[] = [];

            try {
                const entities = await this.bulkGet(batch);

                for (const entity of entities) {
                    if (!entity) {
                        continue;
                    }

                    const entityChanges = callback(entity);

                    entityChangesBatch.push({entity, entityChanges});
                }

                const bulkResult = await this.bulkUpdate(entityChangesBatch);

                result.status = bulkResult.status;
                result.errorMessage = bulkResult.errorMessage;
                result.numberOfCompleted += bulkResult.numberOfCompleted;
            }
            catch (e: any) {
                result.status = BulkUpdateStatus.Error;
                result.errorMessage = e.message;
            }

            if (result.status == BulkUpdateStatus.Error) {
                Logger.error(`[multiUpdate] Error: ${result.errorMessage}`);
                return result;
            }
        }

        return result;
    }
    
    public updateArray(entityId: string, fieldId: string, oldValues: ICrmArrayElement[], newValues: ICrmArrayElement[]): Promise<void> {
        const {add, remove, move} = CalculateArrayModifications(oldValues, newValues);

        if (add.length == 0 && remove.length == 0 && move.length == 0) {
            return Promise.resolve();
        }
    
        const opEventData: ICrmArrayUpdateEvent = {
          fieldId,
          add,
          remove,
          move
        };
    
        let event = {
            type: 'ArrayUpdate',
            tableId: this.tableId,
            entityId,
            data: JSON.stringify(opEventData),
            createdAt: new Date().toISOString(),
            creationContext: JSON.stringify({localId: uuidv4(), clientInstance: this.getClientInstance().id}),
            decodedData: opEventData
        } as ICrmOperationEventDecoded

        return this.shortEventQueue.enqueue({event});
    }
    
    public delete(entityId: string): Promise<void> {
        let event = {
            type: 'DeleteOrder',
            tableId: this.tableId,
            entityId: entityId,
            data: JSON.stringify({}),
            createdAt: new Date().toISOString(),
            creationContext: JSON.stringify({localId: uuidv4(), clientInstance: this.getClientInstance().id}),
            decodedData: {}
        } as ICrmOperationEventDecoded;

        return this.shortEventQueue.enqueue({event});
    }

}